import { Navigate, Outlet } from 'react-router-dom'
import MiniDrawer from '../shared/components/SideBar/sidebar.component'

const ProtectedRoute: React.FC<any> = ({ isAllowed, redirectPath = '*', children }) => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />
    }

    return <MiniDrawer childComponent={<Outlet />} />
}

export default ProtectedRoute
