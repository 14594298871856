import { Backdrop, Button, CircularProgress } from "@mui/material"
import React from "react"
import ModaleValidationComponent from "../../shared/components/Modale/modale-validation.component"
import { toast } from "react-toastify"
import TextEditor from "../CGV/component/TextEditor"
import faqService from "./services/faq.service"

import './Faq.css'

const FaqComponent = () => {
    const [faq, setFaq] = React.useState<string>('')
    const [data, setData] = React.useState<any>()
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [reload, setReload] = React.useState(0)
    const [afficheInput, setAfficheInput] = React.useState(false)
    const handleSave = async () => {
        try {
            setLoading(true)
            await faqService.createFaq({ faq })
            setReload(reload + 1)
        }
        catch (e) {
            console.log(e)
        }
    }
    const handleDelete = async () => {
        try {
            setLoading(true)
            await faqService.deleteFaq(data._id)
            fetchFaq()
            setReload(reload + 1)
        }
        catch (e) {
            toast.error(`Les faqs générales de ventes sont vide`)
            setLoading(false)
            console.log(e)
        }
    }
    console.log(data, 'faq', faq)
    const fetchFaq = () => {
        setLoading(true)
        faqService.getFaq().then((res) => {
            console.log('tafiditra ato', res.data.data[0])
            if (res.data.data.length === 0) {
                setFaq('')
                setAfficheInput(true)
            } else {
                setFaq(res.data.data[0].faq)
                setAfficheInput(false)
            }
            setData(res.data.data[0])
            setLoading(false)
        }).catch((e) => setLoading(false)).finally(() => setLoading(false))
    }
    React.useEffect(() => {
        fetchFaq()
    }, [reload])
    return (
        <div className="flex justify-center flex-col align-center">
            {afficheInput && (
                <TextEditor value={faq} setValue={setFaq} />
            )}
            <div className="contenu-politique ml-5">
                {data && !afficheInput && (
                    <div dangerouslySetInnerHTML={{ __html: faq }} className=" h-[600px] overflow-auto" />
                )}
            </div>
            <div className="mt-5 flex justify-center gap-2">
                {afficheInput && (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            setReload(reload + 1)
                        }}
                    >
                        Annuler
                    </Button>
                )}

                {!afficheInput && (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => { setOpen(true) }}
                    >
                        Supprimer
                    </Button>
                )}
                {!afficheInput && (
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => setAfficheInput(!afficheInput)}
                    >
                        Modifier
                    </Button>
                )}
                {afficheInput && (
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleSave}
                        disabled={faq === '' || faq === '<p><br></p>'}
                    >
                        Enregistrer
                    </Button>
                )}
            </div>
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>

            <ModaleValidationComponent
                openModale={open}
                setOpen={setOpen}
                message={`Voulez-vous supprimer les faqs générales de ventes?`}
                handleValidate={handleDelete}
            />
        </div>
    )
}
export default FaqComponent