import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import paymentService from '../services/payment.service'
import { Payment } from '../interfaces/payment.interface'
import { Card, Typography } from '@mui/material'
import UserPaymentCardComponent from './user-payment-card.component'
import { DateFormater } from '../../../shared/functions/date'

const PaymentDetailsComponent: React.FC<any> = () => {
    const location = useLocation()
    const payment = location.state && location.state.payment
    const [loading, setLoading] = useState(false)
    return (
        <div className="p-5">
            {!loading && (
                <>
                    <div className="w-full grid grid-cols-2 gap-5 py-4">
                        {payment?.payeur && <UserPaymentCardComponent title="Envoyeur" user={payment?.payeur} />}
                        {payment?.receveur && <UserPaymentCardComponent title="Receveur" user={payment?.receveur} />}
                    </div>
                    <Card className="w-full p-5">
                        <div>
                            <Typography fontWeight="bold" className="pb-3">
                                Détails du paiement
                            </Typography>
                        </div>
                        <Typography fontWeight="bold">Somme : </Typography>
                        <Typography className="text-slate-400">{payment?.cout} €</Typography>
                        <Typography fontWeight="bold">Effectué le : </Typography>
                        <Typography className="text-slate-400">{DateFormater(String(payment?.createdAt))}</Typography>
                        <Typography fontWeight="bold">Identifiant : </Typography>
                        <Typography className="text-slate-400">{payment?._id}</Typography>
                    </Card>
                </>
            )}
        </div>
    )
}

export default PaymentDetailsComponent
