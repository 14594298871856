import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import { BASE_COLOR } from '../../constants/color.constant'
import { Grid } from '@mui/material'
import { logOutAction } from '../../../pages/Auth/store/auth.action'
import { store } from '../../../store/App.store'
import { useSelector } from 'react-redux'
import { selectAuth } from '../../../pages/Auth/store/auth.selector'
import { useNavigate } from 'react-router-dom'
import { useMobileBreakPoint } from '../../hooks/mobileBreakPoint'

export interface Action {
    menuTitle: string
    action: () => void
}

function ResponsiveAppBar(props: appBarProps) {
    const { isMobile } = useMobileBreakPoint()

    const firstName = useSelector(selectAuth)?.firstname
    const lastName = useSelector(selectAuth)?.lastname
    const email = useSelector(selectAuth)?.email
    const userName = lastName && firstName ? `${firstName} ${lastName}` : email

    const navigate = useNavigate()
    const settings: Action[] = [
        {
            menuTitle: 'Déconnexion',
            action: () => {
                handleCloseUserMenu()
                store.dispatch(logOutAction())
                localStorage.clear()
                localStorage.removeItem('persist:root')
                window.location.href = '/2420@dminhOw&nkd€v'
            }
        },
        {
            menuTitle: 'Modification de Mot de passe',
            action: () => {
                navigate('/modification-mot-de-passe')
                handleCloseUserMenu()
            }
        }
    ]
    // const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

    // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorElNav(event.currentTarget)
    // }
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    // const handleCloseNavMenu = () => {
    //     setAnchorElNav(null)
    // }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    return (
        <AppBar position="static" elevation={1} sx={{ backgroundColor: BASE_COLOR.WHITE }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters className="w-full" variant="regular" sx={{ color: BASE_COLOR.DARK }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => props.handleDrawerOpen(!props.open)}
                        edge="start"
                        // sx={{
                        //     // marginRight: 5
                        //     // ...(props.open && { display: 'none' })
                        // }}
                    >
                        {props.open ? <MenuOpenIcon /> : /*<img src={logo} alt="logo" style={{ width: '20%' }} />*/ <MenuIcon />}
                    </IconButton>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="h5" noWrap component="div" sx={{ fontWeight: 'bold' }}>
                            {props.PageTitle}
                        </Typography>
                        <Box>
                            <Grid container alignItems="center" justifyContent="end" gap={1}>
                                <div onClick={handleOpenUserMenu} className="flex flex-row items-center gap-2">
                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                    {!isMobile && <Typography>{userName}</Typography>}
                                    {!isMobile && (
                                        <Tooltip title="Open settings">
                                            <IconButton sx={{ p: 0 }}>{anchorElUser ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting: Action, index: number) => (
                                        <MenuItem key={setting.menuTitle} onClick={setting.action}>
                                            <Typography textAlign="center" key={`menu-action-${setting.menuTitle}-${index}`}>
                                                {setting.menuTitle}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Grid>
                        </Box>
                    </Grid>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export interface appBarProps {
    handleDrawerOpen: (value: boolean) => void
    PageTitle: string
    open: boolean
}

export default ResponsiveAppBar
