import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, IconButton, InputAdornment, Link, TextField } from '@mui/material'
import React, { useState } from 'react'
import monImage from '../../../shared/images/logo.svg'
import { AuthInjectedProps } from '../containers/auth.container'
import authServices from '../services/auth.service'
import { useDispatch } from 'react-redux'
import { getAuthAction, setTokenInfoAction } from '../store/auth.action'
import { useNavigate } from 'react-router-dom'
import { LoginResponse } from '../interfaces/auth.interfaces'
import { ApiResponse } from '../../../shared/interface/ApiResponse.interface'
import { toast } from 'react-toastify'
import { BASE_COLOR } from '../../../shared/constants/color.constant'
import { useSelector } from 'react-redux'
import { selectAuth, selectTokenInfo } from '../store/auth.selector'

const LoginComponent: React.FC<AuthInjectedProps> = ({ user, loadAuths }) => {
    const navigate = useNavigate()
    const disptach = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [password, setPassword] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const emailRegex: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    const [emailValidator, setEmailValidator] = useState<boolean | null>(true)
    const [paswordValidator, setPasswordValidator] = useState<boolean | null>(true)
    const handleClickShowPassword = () => setShowPassword(show => !show)

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }
    const tokenInfo = useSelector(selectTokenInfo)
    // const user = useSelector(selectAuth)
    // const navigate = useNavigate();
    console.log('token',tokenInfo,user)
    React.useEffect(()=>{
        if(tokenInfo){
            navigate('/statistiques')
        }
    },[])

    const login = () => {
        if (emailRegex.test(email)) {
            setEmailValidator(true)
            authServices
                .login({
                    email,
                    password
                })
                .then((response: ApiResponse<LoginResponse>) => {
                    if (response.data.success === true) {
                        disptach(setTokenInfoAction(response.data))
                        disptach(getAuthAction(response.data.user))
                        navigate('/statistiques')
                        toast.success('Connexion Reussie')
                    } else {
                        setEmailValidator(false)
                        setPasswordValidator(false)
                        // toast.error('Email ou mot de passe incorrect')
                        toast.error(`${response.data.message}`)
                    }
                })
                .catch(error => toast.error(error))
        } else {
            setEmailValidator(false)
        }
    }

    return (
        <div className="w-screen h-screen bg-primary flex flex-col justify-center items-center gap-8 sm:gap-16">
            <div className="bg-white rounded-[64px] items-center flex justify-center sm:p-5 sm:h-44 sm:w-44 bg-opacity-0 ">
                <img src={monImage} alt="logo" className=" h-20 sm:h-40" />
            </div>
            <div className="bg-white rounded-lg">
                <div className="mx-[45px] my-[32px] flex flex-col gap-3">
                    <TextField
                        label={`Adresse mail`}
                        error={!emailValidator}
                        onChange={(e: any) => {
                            setEmail(e.target.value.trim())
                        }}
                        id="outlined-start-adornment"
                        variant="standard"
                        sx={{ m: 1, width: '25ch' }}
                        helperText={!emailValidator ? 'email incorrect' : ''}
                    />
                    <TextField
                        label={`Mot de passe`}
                        error={!paswordValidator}
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e: any) => {
                            setPassword(e.target.value)
                        }}
                        variant="standard"
                        sx={{ m: 1, width: '25ch' }}
                        helperText={!paswordValidator ? 'mot de passe incorrect' : ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <div className="flex justify-center w-full">
                        <Button
                            variant="contained"
                            //  color="primary"
                            sx={{ borderRadius: '20px' }}
                            onClick={login}
                            className="w-2/3 !bg-[#0B7D75]"
                        >
                            Valider
                        </Button>
                    </div>
                    <div className="flex justify-center">
                        <Link href="/mot-de-passe-oublier" underline="hover" style={{ color: '#0B7D75' }}>
                            Mot de passe oublié ?
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginComponent
