import { Domaine } from '../interfaces/domaine.interface'
import { DomaineActionTypes, DomaineActions } from './domaine.action'

interface DomaineState {
    domaines: Domaine[]
}

const initialState: DomaineState = {
    domaines: []
}

const domaineReducer = (state: DomaineState = initialState, action: DomaineActions): DomaineState => {
   // console.log("vvvvvvvvvvvvvvvvvv lllll",DomaineActionTypes.SET_DOMAINES,action)
    switch (action.type) {
        case DomaineActionTypes.SET_DOMAINES:
            // console.log("domaine",action,action.domaines)
            return {
                ...state,
                domaines: action.domaines
            }

        case DomaineActionTypes.ADD_DOMAINE:
            return {
                ...state,
                domaines: [...state.domaines, action.domaine]
            }
        default:
            return state
    }
}

export default domaineReducer
