import { Message } from '../interface/message.interface'
import { MessageActionTypes, MessageActions } from './message.action'

interface MessageState {
    messages: Message[]
}

const initialState: MessageState = {
    messages: [],
}

const messageReducer = (state: MessageState = initialState, action: MessageActions): MessageState => {
  //  console.log("vvvvvvvvvvvvvvvvvv",MessageActionTypes.GET_MESSAGE,action)
    switch (action.type) {
        case MessageActionTypes.GET_MESSAGE:
            return {
                ...state,
                messages: action.messages
            }
        default:
            return state
    }
}

export default messageReducer
