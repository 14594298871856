import httpCommon from "../../../shared/services/http-common";
import { politique } from "../util";

class PolitiqueService {
    async getPolitique() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/getPolitique`)
    }
    async createPolitique(data: politique) {
        try {
            const politique = await this.getPolitique();
            if (politique.data.data.length === 0) {
                return httpCommon.post(`${process.env.REACT_APP_API_ROOT}/createPolitique`, data)
            } else {
                return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/updatePolitique/${politique.data.data[0]._id}`, data)
            }
        }
        catch (e) {
            console.error(e)
        }
    }
    async deletePolitique(id: string) {
        try {
            if (id) {
                return httpCommon.delete(`${process.env.REACT_APP_API_ROOT}/deletePolitique/${id}`)
            }
            console.log('id', id)
        }
        catch (e) {
            console.error(e)
        }
    }
}
const politiqueService = new PolitiqueService()
export default politiqueService