import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { Box, Grid, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export default function ModaleValidationDeblocageComponent(props: Modaleprops) {
    const { openModale, message, handleValidate, setOpen } = props

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Dialog
                open={openModale}
                onClose={handleClose}
                sx={{
                    bottom: '40%'
                }}
            >
                <DialogTitle textAlign={'center'} sx={{ fontSize: 15 }}>
                    <DeleteOutlineOutlinedIcon sx={{ fontSize: 48, color: 'grey' }} />
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon sx={{ fontSize: 25 }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ textAlign: 'center', minWidth: 500 }}>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>

                <Box sx={{ marginBottom: 4 }}>
                    <Grid container justifyContent="center" alignItems="center" gap={1}>
                        <Grid item>
                            <Button variant="outlined" className="!w-[200px]" onClick={handleClose}>
                                Annuler
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                className="!w-[200px]"
                                color="error"
                                onClick={() => {
                                    handleValidate()
                                    handleClose()
                                }}
                            >
                                Débloquer
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </div>
    )
}

export interface Modaleprops {
    openModale: boolean
    setOpen: (open: boolean) => void
    message: string
    handleValidate: () => void
}
