import { Card, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { User } from '../../Utilisateur/interfaces/utilisateur.interface'

interface UserPaymentCardComponentProps {
    title: ReactNode
    user: User
}

const UserPaymentCardComponent: React.FC<UserPaymentCardComponentProps> = ({ title, user }) => {
    return (
        <Card>
            <Typography className="px-8 pt-3" fontWeight="bold" variant="h5" component="div">
                {title ?? ''}
            </Typography>
            <div className="grid grid-cols-10 gap-4">
                <div className="flex justify-center items-center">
                    <Typography></Typography>
                </div>
                <div className="col-span-9 flex flex-col">
                    <Typography fontWeight="bold">Nom</Typography>
                    <Typography className="text-slate-400">
                        {user.firstname} {user.lastname}
                    </Typography>
                    <Typography fontWeight="bold">Email</Typography>
                    <Typography className="text-slate-400">{user.email}</Typography>
                    <Typography fontWeight="bold">Adresse</Typography>
                    <Typography className="text-slate-400">{user.localisation[0]?.address}</Typography>
                </div>
            </div>
        </Card>
    )
}

export default UserPaymentCardComponent
