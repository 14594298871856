import {
    Button,
    Grid,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    styled,
    tableCellClasses
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { BASE_COLOR } from '../../../shared/constants/color.constant'
import ModaleValidationComponent from '../../../shared/components/Modale/modale-validation.component'
import { useEffect, useState } from 'react'
import { Domaine, DomaineCreateResponse, DomaineResponse } from '../interfaces/domaine.interface'
import { DomaineInjectedProps } from '../containers/domaine.container'
import { useDispatch } from 'react-redux'
import { addDomaineAction, setDomaineAction } from '../store/domaine.action'
import domaineServices from '../services/domaine.service'
import { toast } from 'react-toastify'
import { ApiResponse } from '../../../shared/interface/ApiResponse.interface'
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: BASE_COLOR.CONTAINER,
        border: 0
    },
    '&:last-child td, &:last-child th': {
        border: 0
    },
    [`& .${tableCellClasses.root}`]: {
        borderBottom: 'none',
        width: '100vw'
    }
}))

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: BASE_COLOR.CONTAINER,
        // fontSize: 15,
        fontWeight: 'bold'
        // textTransform: 'uppercase'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15
    }
}))

const DomaineComponent: React.FC<DomaineInjectedProps> = ({ domaines, loadDomaines }) => {
    const disptach = useDispatch()
    const [toUpdate, setToUpdate] = useState<Domaine['_id'] | null>(null)
    const [epiToUpdate, setEpiToUpdate] = useState<number | null>(null)
    const [open, setOpen] = useState(false)
    const [modeConfirm, setModeConfirm] = useState<'deletion' | 'update' | null>(null)
    const [selectedItem, setSelectedItem] = useState('')
    const [domaineData, setDomaineData] = useState<Domaine[]>([])
    const [inputDomaine, setInputDomaine] = useState('')
    const [inputRate, setInputRate] = useState(0)
    const [onModifyRate, setOnModifyRate] = useState<number | null>(null)
    const [activeAccordeon, setActiveAccordeon] = useState<number[]>([])
    const [inputEpi, setInputEpi] = useState('')
    const [inputEpiIsDirty, setInputEpiIsDirty] = useState(false)
    const [selectedEpi, setSelectedEpi] = useState<{ domaineId: Domaine['_id']; epi: string } | null>(null)
    const [showAddEpi, setShowAddEpi] = useState<Domaine['_id'][]>([])
    const [openEpiDeletionConfirmation, setOpenEpiDeletionConfirmation] = useState(false)
    const [isToastVisibleRate, setIsToastVisibleRate] = useState(true);
    const [isButtonUpdateRateEnabled, setIsButtonUpdateRateEnabled] = useState(true);
    const [isButtonUpdateEpiEnabled, setIsButtonUpdateEpiEnabled] = useState(true);

    const onClickDomaine = (index: number) => {
        if (activeAccordeon.includes(index)) setActiveAccordeon(previous => previous.filter(elt => elt !== index))
        else setActiveAccordeon(previous => [...previous, index])
    }

    useEffect(() => {
        updateDomaine()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        // setIsToastVisibleRate(true)
        setIsButtonUpdateRateEnabled(true)
    }, [inputRate])

    useEffect(() => {
        setIsButtonUpdateEpiEnabled(true)
    }, [inputEpi])

    const updateOne = (id: Domaine['_id'] | null) => {
        if (id) domaineServices.update(id, inputDomaine).then(updateDomaine).catch(console.log)
    }

    const onModifyRateFunction = (domainId: Domaine['_id'], newMinimumRate: number) => {
        setIsButtonUpdateRateEnabled(false)
        domaineServices
            .update(domainId, undefined, newMinimumRate)
            .then(() => {
                toast.success('Frais minimum pour le domaine mis à jour.')
                updateDomaine()
            })
            .finally(() => {
                setOnModifyRate(null)
            })
    }

    const updateEpi = (id: Domaine['_id'], epiIndex: number) => {
        setIsButtonUpdateEpiEnabled(false)
        domaineServices
            .updateAnEpi(id, epiIndex, inputEpi)
            .then(() => {
                toast.success('Epi mis à jour')
                updateDomaine()
            })
            .finally(() => {
                setShowAddEpi([])
                setEpiToUpdate(null)
                setInputEpiIsDirty(false)
            })
    }

    const updateDomaine = () => {
        domaineServices.get().then((e: ApiResponse<DomaineResponse>) => {
            setDomaineData(e.data.domaines)
            disptach(setDomaineAction(e.data.domaines))
        })
    }

    const createDomaine = () => {
        if (inputDomaine !== '')
            domaineServices
                .create(inputDomaine)
                .then((e: ApiResponse<DomaineCreateResponse>) => {
                    setDomaineData([...domaineData, e.data.data])
                    disptach(addDomaineAction(e.data.data))
                    // setDomaineData(domaines)
                    // updateDomaine()
                    toast.success('Domaine créé avec succès.')
                })
                .catch(error => toast.error('Ce domaine existe déjà'))
                // .catch(error => toast.error('error'))
    }

    const createEpi = (id: Domaine['_id']) => {
        if (inputEpi !== '')
            domaineServices
                .addNewEpi(id, inputEpi)
                .then((e: ApiResponse<DomaineCreateResponse>) => {
                    // setDomaineData(domaines)
                    // updateDomaine()
                    toast.success('Epi créé avec succès.')
                    updateDomaine()
                })
                .finally(() => {
                    setShowAddEpi([])
                    setInputEpi('')
                    setInputEpiIsDirty(false)
                })
    }

    const deleteDomaine = (id: string) => {
        domaineServices
            .delete(id)
            .then(() => {
                toast.success('Domaine supprimé avec succès.')
                updateDomaine()
            })
            .catch(error => toast.error(error))
            .finally(() => setSelectedItem(''))
    }

    const deleteAnEpi = (id: Domaine['_id'], epi: string) => {
        domaineServices
            .deleteAnEpi(id, epi)
            .then(() => {
                toast.success('Domaine supprimé avec succès.')
                updateDomaine()
            })
            .catch(error => toast.error(error))
            .finally(() => {
                setSelectedItem('')
                setSelectedEpi(null)
            })
    }

    const [order, setOrder] = useState<'asc' | 'desc'>('asc')
    const [orderBy, setOrderBy] = useState<string>('fullName')

    const handleSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc'
        const newOrder = isAsc ? 'desc' : 'asc'
        setOrder(newOrder)
        setOrderBy(property)

        const sorted = [...domaineData].sort((a, b) => (newOrder === 'asc' ? handleSortBy(a, b, property) : handleSortBy(b, a, property)))

        setDomaineData(sorted)
    }

    const handleSortBy = (a: Domaine, b: Domaine, property: string) => {
        return (a[property] ?? '').localeCompare(b[property])
    }
    return (
        <>
            <div className="pl-5 py-3 w-full grid grid-cols-5 gap-4">
                <Input placeholder="Domaine" onChange={e => setInputDomaine(e.target.value)} fullWidth />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={createDomaine}
                    fullWidth
                    disabled={inputDomaine === ''}
                    //sx={{ backgroundColor: BASE_COLOR.PRIMARY }}
                >
                    Ajouter un domaine
                </Button>
            </div>
            <TableContainer component="div" sx={{ maxHeight: 500 }} className="px-7">
                <Table
                    stickyHeader
                    sx={{
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: 'none'
                        }
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableHeader sortDirection={orderBy === 'name' ? order : false}>
                                <TableSortLabel
                                    active={true}
                                    direction={orderBy === 'name' ? order : 'asc'}
                                    onClick={() => handleSort('name')}
                                >
                                    Domaine
                                </TableSortLabel>
                            </StyledTableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {domaineData?.map((row: Domaine, i: number) => (
                            <>
                                <div key={i} className={`w-full grid grid-cols-2 ${i % 2 === 1 ? 'even:bg-[#FAF9F5]' : ''}`}>
                                    <TableCell onClick={() => onClickDomaine(i)} className="cursor-pointer">
                                        {row._id === toUpdate ? (
                                            <>
                                                <TextField
                                                    value={inputDomaine}
                                                    label={"Edition d'un domaine"}
                                                    error={inputDomaine === ''}
                                                    id="outlined-start-adornment"
                                                    onChange={(e: any) => setInputDomaine(e.target.value)}
                                                    variant="outlined"
                                                    sx={{ m: 1, width: '25ch' }}
                                                    helperText={inputDomaine === '' ? 'Ce champ ne peut être vide' : ''}
                                                />
                                            </>
                                        ) : (
                                            <div className="flex justify-between">
                                                <div>{row.name}</div>
                                                <div>
                                                    {!activeAccordeon.includes(i) ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
                                                </div>
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {toUpdate === row._id ? (
                                            <Grid container alignItems={'center'} gap={1} sx={{ justifyContent: 'end' }}>
                                                <CheckCircleOutline
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        updateOne(row._id)
                                                        setToUpdate(null)
                                                    }}
                                                />
                                                <CancelOutlined className="cursor-pointer" onClick={() => setToUpdate(null)} />
                                            </Grid>
                                        ) : (
                                            <Grid container alignItems={'center'} gap={1} sx={{ justifyContent: 'end' }}>
                                                <EditIcon
                                                    color="primary"
                                                    onClick={() => {
                                                        setToUpdate(row._id)
                                                        setInputDomaine(row.name)
                                                    }}
                                                    className="cursor-pointer"
                                                />
                                                <DeleteIcon
                                                    color="error"
                                                    onClick={() => {
                                                        setOpen(true)
                                                        setModeConfirm('deletion')
                                                        setSelectedItem(row._id)
                                                    }}
                                                    className="cursor-pointer"
                                                />
                                            </Grid>
                                        )}
                                    </TableCell>
                                </div>
                                {activeAccordeon.includes(i) ? (
                                    <div className="w-full">
                                        <div className="w-full grid grid-cols-3">
                                            {onModifyRate !== i ? (
                                                <>
                                                    <TableCell>Tarif horaire minimum</TableCell>
                                                    <TableCell>{row.minimumRate ?? 20} €</TableCell>
                                                    <TableCell>
                                                        <EditIcon
                                                            onClick={() => {
                                                                setOnModifyRate(i)
                                                                setInputRate(row.minimumRate ?? 20)
                                                            }}
                                                            className="cursor-pointer"
                                                        />
                                                    </TableCell>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="w-1/3">
                                                        <TextField
                                                            value={inputRate}
                                                            label={'Frais minimum'}
                                                            error={inputRate === 0}
                                                            id="outlined-start-adornment"
                                                            onChange={(e: any) => setInputRate(+e.target.value)}
                                                            variant="outlined"
                                                            sx={{ m: 1, width: '25ch' }}
                                                            helperText={inputRate === 0 ? 'Ce champ ne peut être vide' : ''}
                                                        />
                                                        <Grid container alignItems={'center'} gap={1} sx={{ justifyContent: 'end' }}>
                                                            <CheckCircleOutline
                                                                className="cursor-pointer"
                                                                onClick={() => isButtonUpdateRateEnabled && onModifyRateFunction(row._id, inputRate)}
                                                            />
                                                            <CancelOutlined
                                                                className="cursor-pointer"
                                                                onClick={() => setOnModifyRate(null)}
                                                            />
                                                        </Grid>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {!showAddEpi.includes(row._id) ? (
                                            row.epis && row.epis.length > 0 ? (
                                                row.epis.map((epi, epiIndex) => {
                                                    return (
                                                        <div className="w-full grid grid-cols-3">
                                                            <TableCell>{epi}</TableCell>
                                                            <TableCell>
                                                                <EditIcon
                                                                    onClick={() => {
                                                                        setEpiToUpdate(epiIndex)
                                                                        setShowAddEpi(prev => [...prev, row._id])
                                                                        setInputEpi(epi)
                                                                    }}
                                                                    className="cursor-pointer"
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <DeleteIcon
                                                                    onClick={() => {
                                                                        setSelectedEpi({ domaineId: row._id, epi })
                                                                        setOpenEpiDeletionConfirmation(true)
                                                                    }}
                                                                    className="cursor-pointer"
                                                                />
                                                            </TableCell>
                                                        </div>
                                                    )
                                                })
                                            ) : (
                                                <TableCell>Aucun EPI enregistré pour ce domaine</TableCell>
                                            )
                                        ) : (
                                            <div className="w-1/3">
                                                <TextField
                                                    value={inputEpi}
                                                    label={'Ajout EPI'}
                                                    error={inputEpi === '' && inputEpiIsDirty}
                                                    id="outlined-start-adornment"
                                                    onChange={(e: any) => {
                                                        setInputEpi(e.target.value)
                                                        setInputEpiIsDirty(true)
                                                    }}
                                                    variant="outlined"
                                                    sx={{ m: 1, width: '25ch' }}
                                                    helperText={inputEpi === '' && inputEpiIsDirty ? 'Ce champ ne peut être vide' : ''}
                                                />
                                                <Grid container alignItems={'center'} gap={1} sx={{ justifyContent: 'end' }}>
                                                    <CheckCircleOutline
                                                        className="cursor-pointer"
                                                        onClick={() => isButtonUpdateEpiEnabled && (
                                                            epiToUpdate === null ? createEpi(row._id) : updateEpi(row._id, epiToUpdate)
                                                        )}
                                                    />
                                                    <CancelOutlined
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            setShowAddEpi([])
                                                            setInputEpi('')
                                                            setInputEpiIsDirty(false)
                                                        }}
                                                    />
                                                </Grid>
                                            </div>
                                        )}
                                        {!showAddEpi.includes(row._id) && (
                                            <TableCell
                                                onClick={() => {
                                                    setInputEpi('')
                                                    setShowAddEpi(prev => [...prev, row._id])
                                                }}
                                                className="cursor-pointer text-primary"
                                            >
                                                <Button className="flex justify-between">
                                                    Ajouter un nouveau EPI
                                                    <AddIcon color="primary" />
                                                </Button>
                                            </TableCell>
                                        )}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* <Box sx={{ display: 'flex', mt: 5 }}>
                 <Grid container alignItems="center" justifyContent="end" gap={1}>
                    <Stack spacing={2}>
                        <Pagination
 
                            count={10}
 
                            onChange={() => {}}
                            color="primary"
                            shape="rounded"  
                        />
                    </Stack>

                    <FormControl sx={{ m: 1, minWidth: 120, background: 'white' }} size="small">
                        <Select value={itemParPage} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem value={'5'}>5 / page</MenuItem>
                            <MenuItem value={'10'}>10 / page</MenuItem>
                            <MenuItem value={'25'}>25 / page</MenuItem>
                        </Select>
                    </FormControl>
                </Grid> 
            </Box> */}
            <ModaleValidationComponent
                openModale={open}
                setOpen={setOpen}
                message={`Voulez-vous supprimer ce domaine ?`}
                handleValidate={() => {
                    if (modeConfirm) {
                        if (modeConfirm === 'deletion') {
                            deleteDomaine(selectedItem)
                        } else {
                            updateOne(toUpdate)
                        }
                    }
                }}
            />
            <ModaleValidationComponent
                openModale={openEpiDeletionConfirmation}
                setOpen={setOpenEpiDeletionConfirmation}
                message={`Voulez-vous supprimer cet EPI ?`}
                handleValidate={() => {
                    if (selectedEpi) deleteAnEpi(selectedEpi.domaineId, selectedEpi.epi)
                }}
            />
        </>
    )
}
export default DomaineComponent
