import httpCommon from "../../../shared/services/http-common";
import { faq } from "../util";

class FaqService {
    async getFaq() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/getFaq`)
    }
    async createFaq(data: faq) {
        try {
            const faq = await this.getFaq();
            if (faq.data.data.length === 0) {
                return httpCommon.post(`${process.env.REACT_APP_API_ROOT}/createFaq`, data)
            } else {
                return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/updateFaq/${faq.data.data[0]._id}`, data)
            }
        }
        catch (e) {
            console.error(e)
        }
    }
    async deleteFaq(id: string) {
        try {
            if (id) {
                return httpCommon.delete(`${process.env.REACT_APP_API_ROOT}/deleteFaq/${id}`)
            }
            console.log('id', id)
        }
        catch (e) {
            console.error(e)
        }
    }
}
const faqService = new FaqService()
export default faqService