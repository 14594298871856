import React, { useState } from "react"
import DeleteIcon from '@mui/icons-material/Delete'
import messService from "./Message.service"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectMessage } from "./store/message.selector";
import { MessInjectedProps } from "./containers/message.container";
import { getMessageAction } from "./store/message.action";
// import styled from 'styled-components';
import { Backdrop, Checkbox, CircularProgress, InputLabel, ListItemText, MenuItem, Select, styled } from "@mui/material";
interface Option {
  value: string,
  label: string
}
const MessageR : React.FC<MessInjectedProps> = ({ messages, loadMessages }) => {
  const [message, setMessage] = React.useState('');
  const [idMess, setIdMes] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [selectMultiple, setSelectMultiple] = useState <string []>([])
  const [selectMultiple2, setSelectMultiple2] = useState <string []>([])
const options : Option[ ]= [
  { value: 'Chocolate', label: 'Chocolate' },
  { value: 'Strawberry', label: 'Strawberry' },
  { value: 'Vanilla', label: 'Vanilla' }
];
const options2 : Option[ ]= [
  { value: 'Pink', label: 'Pink' },
  { value: 'White', label: 'White' },
  { value: 'Red', label: 'Red' }
];

  const messa = useSelector(selectMessage)

  const handleMessageChange = async(e:any) => {
   setMessage(e.target.value);
  };
  const dispatch = useDispatch()
  const listMess = async () =>{
    setLoading(true)
    await messService.getMessage()
      .then((resp:any)=>{
       // console.log("response ici data",resp.data.data)
        dispatch(getMessageAction(resp.data.data))
      })
      .catch(()=>{})
      .finally(()=>setLoading(false))
  }
  React.useEffect(()=>{
    listMess()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };
  React.useEffect(()=>{
    scrollToBottom()
  },[messa]);
  const notifierUser = async()=>{
    if(idMess.length>0){
      await messService.getMessageID(idMess)
    }
  }
  React.useEffect(()=>{
    notifierUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[idMess])


  const handleMessageSubmit = async(e:any) => {
    e.preventDefault();
    if (message.trim() !== '') {
      try {
        const data ={
          content:message
        }
        setLoading(true)
        const resp=await messService.CreateMesage(data)
        setLoading(false)
        dispatch(getMessageAction([...messa,resp.data.data]))
        setIdMes(resp.data.data._id)
        setMessage('')
      }
      catch (e) {
          console.log(e)
          setLoading(false)
      }
    }
  };

  const handleSelectedChange = (e: any) =>{
    setSelectMultiple(e.target.value)
  }
  const handleSelectedChange2 = (e: any) =>{
    setSelectMultiple2(e.target.value)
  }

    return (
        <div className="h-[90%] flex flex-col">
            <div className="flex-1 ">
                <div className="w-full flex flex-col">
                {messa && messa.map((msg: any, index: number) => (
                    <div key={index} className="flex  justify-between p-2 overflow-y-auto">
                      <div className="flex flex-row bg-white-300 justify-between flex-1">
                          <div className=" text-black rounded-md p-2">
                          {msg.content}
                          </div>
                          <div className="mr-9">
                            <DeleteIcon
                                color="error"
                                onClick={() => {}}
                                className="cursor-pointer"
                            />
                          </div>
                      </div>
                    </div>
                ))}
                </div>
            </div>
            <div className="p-4">
            <InputLabel id="multiple-select-label">Select Options</InputLabel>
                <Select
                  labelId="multiple-select-label"
                  multiple
                  value={selectMultiple}
                  onChange={(e) => {handleSelectedChange(e)}}
                  renderValue={(selected) => selected.join(', ')}
                  style={{width : '45%' , borderRadius : "12px"}}
                >
                  <em>Domaine</em>
                   {options.map((option) => (
                    <MenuItem key={option.value} value={option.value} >
                      <Checkbox checked={selectMultiple.indexOf(option.value) > -1}/>
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  
                  labelId="multiple-select-label"
                  placeholder="Selectionner domaine "
                  multiple
                  value={selectMultiple2}
                  onChange={(e) => {handleSelectedChange2(e)}}
                  renderValue={(selected) => selected.join(', ')}
                  style={{width : '45%' , borderRadius : "12px", marginLeft: "20px"}}
                 >
                    <em>Domaine 2</em>
                   {options2.map((option) => (
                    <MenuItem key={option.value} value={option.value} >
                      <Checkbox checked={selectMultiple2.indexOf(option.value) > -1}/>
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                 </Select>
            </div>
                 
            
            <form onSubmit={handleMessageSubmit} className="flex justify-between items-center p-4">
                
                <input
                  type="text"
                  value={message}
                  onChange={handleMessageChange}
                  placeholder="Entrez votre message..."
                  className="flex-1 px-4 py-2 rounded-full bg-gray-200 focus:outline-none focus:ring focus:border-blue-300"
                />
                <button
                  type="submit"
                  className="ml-2 px-4 py-2 rounded-full bg-blue-500 text-white font-semibold hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
                >
                Envoyer
                </button>
            </form>
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>
        </div>
    )
}
export default MessageR