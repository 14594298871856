import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import userServices from '../services/utilisateur.service'; // Assurez-vous d'avoir un service pour gérer l'authentification

interface UtilisateurConfirmSupprModalProps {
  open: boolean;
  onClose: () => void;
  onAuthenticate: (itemId: string) => void;
  // selectedItem: string | null;
  selectedItem: string;
}

const UtilisateurConfirmSupprModal : React.FC<UtilisateurConfirmSupprModalProps> = ({ open, onClose, onAuthenticate, selectedItem }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    try {
      const response = await userServices.verifierConfirmationSuppression(selectedItem, email, password);
      if (response.data.success) {
      // console.log(response.data.success)
        onAuthenticate(selectedItem); // Authentification réussie, passer l'ID à la fonction de suppression
        setError('');
      } else {
        setError('Identifiants incorrects.');
      }
    } catch (err) {
      // setError('Une erreur est survenue.' + err);
      setError('Une erreur est survenue.');
    }
    setEmail('')
    setPassword('')
  };

  const closePopUpForm = () => {
    setEmail('')
    setPassword('')
    onClose()
  }

  return (
    <Modal open={open} onClose={closePopUpForm}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h6" component="h2">Entrez vos identifiants pour confirmer la suppression</Typography>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Mot de passe"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        {error && <Typography color="error">{error}</Typography>}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
          <Button onClick={handleLogin} variant="contained"  style={{ backgroundColor: 'red', color: 'white' }}>Confirmer la suppression</Button>
          {/* <Button onClick={onClose} variant="contained">Confirmer</Button> */}
          <Button onClick={closePopUpForm} variant="outlined">Annuler</Button>
        </Box>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default UtilisateurConfirmSupprModal;
