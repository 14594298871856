import React from 'react';
import styled from '@emotion/styled'
// import '../css/CenteredImage.css'; 
import Header from '../../NavBar/components/header';
import Footer from '../../NavBar/components/Footer';
import DeleteIcon from '@mui/icons-material/Delete'
import {
    Grid,
    TableContainer,
    Table,
    tableCellClasses,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Box,
    Pagination,
    Stack,
    Backdrop,
    CircularProgress,
    FormControl,
    Select,
    MenuItem,
    TableSortLabel
} from '@mui/material'
import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react'
import userServices from '../services/utilisateur.service'
import { IDomaines, User } from '../interfaces/utilisateur.interface'
import { BASE_COLOR } from '../../../shared/constants/color.constant'
import { DateFormater } from '../../../shared/functions/date'
import UtilisateurConfirmSupprModal from './utilisateur-confirm-suppr-modal';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
      backgroundColor: BASE_COLOR.CONTAINER,
      border: 0
  },
  [`& .${tableCellClasses.root}`]: {
      borderBottom: 'none'
  }
}))

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: BASE_COLOR.CONTAINER,
      fontWeight: 'bold'
  },
  [`&.${tableCellClasses.body}`]: {
      fontSize: 15
  }
}))

const CenteredContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px', // Espace entre les éléments du haut et le tableau
});

const StyledTableContainer = styled(TableContainer)({
    maxWidth: '100%',
    overflowX: 'auto', // Scroll horizontal
    maxHeight: '400px', // Hauteur max pour le scroll vertical
    overflowY: 'auto', // Scroll vertical
});

const UtilisateurASupprimerComponent = () => {
  const [emailUser, setEmailUser] = React.useState<string>("") 
  const [createurs, setCreateurs] = useState<User[]>([]) 
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [orderBy, setOrderBy] = useState<string>('fullName')
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState(0)
  const [pageLastButton, setPageLastButton] = useState<number>()
  const [aucunDonnees,setAucunDonnees] = useState<string>("")

  const [selectedItem, setSelectedItem] = useState<User['_id']>("")
  const [openLoginModal, setOpenLoginModal] = useState(false);

    // const onChangeQuery = (query: any) =>
    //     setInputQuery((prevQuery: any) => ({
    //         ...prevQuery,
    //         ...query
    //     }))

        // const onChangeFields = (name: string, value: any) => {
        //            onChangeUser &&
        //             onChangeUser({
        //                 ...user,
        //                 [name]: value
        //             })
        // }

        const handleAuthenticate = async (itemId: string) => {
            try {
                setLoading(true)
              await userServices.deleteAUser(itemId); // Appelez le service de suppression
              // Actualisez votre état pour refléter la suppression, par exemple en supprimant l'élément de la liste
              userServices
              .getASuppr(page, rowsPerPage, emailUser)
              .then(res => {
                  /*Filtrer pour que seulent les user ayant un tva apparaissent*/
                  // const filteredData = res.data.data.filter((user: User) => user.idEntreprise?.tva !== '');  
                  // setCreateurs(res.data.data)
                  setCreateurs(res.data.data)
                   
                  setTotalPage(res.data.totalPage)
                  console.log("TotalPage selon les donnees: ",res.data.totalPage)
                  console.log("rowsPerPage: ",rowsPerPage)

                  setPageLastButton(Math.ceil(createurs.length / rowsPerPage))
                  setRowsPerPage(rowsPerPage)

                  if (emailUser === "") {
                      setAucunDonnees("Aucun enregistrement trouvé")
                  }else{
                      setAucunDonnees("")
                  }
                  
              })
              .catch(error => console.log(error))
              .finally(() => setLoading(false))

              setOpenLoginModal(false);
            } catch (error) {
              console.log('Erreur lors de la suppression:', error);
            }
          };

        const handleDeleteClick = (itemId: string) => {
            setSelectedItem(itemId);
            setOpenLoginModal(true);
          };

  const handleChangeRowsPerPage = (value: number) => setRowsPerPage(value)

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage)
}

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    const newOrder = isAsc ? 'desc' : 'asc'
    setOrder(newOrder)
    setOrderBy(property)

    const sorted = [...createurs].sort((a, b) => (newOrder === 'asc' ? handleSortBy(a, b, property) : handleSortBy(b, a, property)))

    setCreateurs(sorted)
}
  const handleSortBy = (a: User, b: User, property: string) => {
    switch (property) {
        case 'fullName':
            return `${a['lastname']} ${a['firstname']}`.localeCompare(`${b['lastname']} ${b['firstname']}`)
        case 'nomEntreprise':
            return (a.idEntreprise?.nomEntreprise ?? '').localeCompare(b.idEntreprise?.nomEntreprise ?? '')
        default:
            return (a[property] ?? '').localeCompare(b[property])
    }
  }

  useEffect(() => {
    setLoading(true)
    // if (emailUser !== "") {
        userServices
            .getASuppr(page, rowsPerPage, emailUser)
            .then(res => {
                /*Filtrer pour que seulent les user ayant un tva apparaissent*/
                // const filteredData = res.data.data.filter((user: User) => user.idEntreprise?.tva !== '');  
                // setCreateurs(res.data.data)
                    setCreateurs(res.data.data)
                    
                    setTotalPage(res.data.totalPage)
                    console.log("TotalPage selon les donnees: ",res.data.totalPage)
                    console.log("rowsPerPage: ",rowsPerPage)

                    setPageLastButton(Math.ceil(createurs.length / rowsPerPage))
                    setRowsPerPage(rowsPerPage)
                
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    // }
    
}, [page, rowsPerPage, totalPage])

  const rechercherCompte = () => {
    setPage(1)
    setLoading(true)
    userServices
                .getASuppr(page, rowsPerPage, emailUser)
                .then(res => {
                    /*Filtrer pour que seulent les user ayant un tva apparaissent*/
                    // const filteredData = res.data.data.filter((user: User) => user.idEntreprise?.tva !== '');  
                    // setCreateurs(res.data.data)
                    setCreateurs(res.data.data)
                     
                    setTotalPage(res.data.totalPage)
                    console.log("TotalPage selon les donnees: ",res.data.totalPage)
                    console.log("rowsPerPage: ",rowsPerPage)

                    setPageLastButton(Math.ceil(createurs.length / rowsPerPage))
                    setRowsPerPage(rowsPerPage)

                    if (emailUser === "") {
                        setAucunDonnees("Aucun enregistrement trouvé")
                    }else{
                        setAucunDonnees("")
                    }
                    
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
  }


  return (
    <>
      {/* <div className="centered-image-container"> */}
      <div>
        <h2 style={{ fontWeight: 'bold', marginBottom: '20px', textAlign: 'center' }}>
        Recherchez le compte a supprimer
      </h2>
      {/* <p style={{ lineHeight: '1.6', textAlign: 'left' }}>
        Pour commencer, suivez ces étapes:
      </p> */}
      <ol style={{  fontSize: '0.9em',lineHeight: '1.8', textAlign: 'left', margin: '20px auto', maxWidth: '600px', fontFamily: 'Arial, sans-serif' }}>
        <li><strong>1.</strong>Rechercher votre adresse e-mail sur la barre de recherche et cliquez sur le bouton "RECHERCHER"</li>
        <li><strong>2.</strong>Sur le tableau qui s'affiche: cliquer sur l'icone de poubelle en rouge à côté de vos informations</li>
        <li><strong>3.</strong>Dans le formulaire qui s'affiche: entrez votre e-mail et votre mot de passe</li>
        <li><strong>4.</strong>Cliquez sur "confirmer la suppression" pour supprimer votre compte ou sur "annuler" pour annuler la suppression </li>
      </ol>
    
        {/* <Input size="md" placeholder="E-mail" /> */}
        <CenteredContainer>
        <TextField
                        label={`Adresse mail`}
                        // // error={!emailValidator}
                        onChange={(e: any) => {
                          setEmailUser(e.target.value.trim())
                        }}
                        id="outlined-start-adornment"
                        variant="standard"
                        sx={{ m: 1, width: '25ch' }}
                        // helperText={!emailValidator ? 'email incorrect' : ''}
                    />
        
        
            <Button
            variant="contained"
            //  color="primary"
            sx={{ borderRadius: '20px' }}
            onClick={rechercherCompte}
            // className="w-2/3 !bg-[#0B7D75]"
            >
            Rechercher
          </Button>
          </CenteredContainer>
          <div>
          <TableContainer component="div" sx={{ maxWidth: 1200, width: '100%', display: 'block' }}>
            {/* <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}> */}
                {createurs.length === 0 && !loading ? (
                    // emailUser !=="" &&(
                    <p style={{ display: 'flex', justifyContent: 'center' }}>{aucunDonnees}</p>
                    // )
                ) : (
                    <Table
                        stickyHeader
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: 'none'
                            }
                        }}
                        className="border table-auto mx-9"
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableHeader sortDirection={orderBy === 'fullName' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'fullName' ? order : 'asc'}
                                        onClick={() => handleSort('fullName')}
                                    >
                                        Nom et prénom
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'email' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'email' ? order : 'asc'}
                                        onClick={() => handleSort('email')}
                                    >
                                        Email
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'numero' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'numero' ? order : 'asc'}
                                        onClick={() => handleSort('numero')}
                                    >
                                        Téléphone
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'ville' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'ville' ? order : 'asc'}
                                        onClick={() => handleSort('ville')}
                                    >
                                        Ville
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'nomEntreprise' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'nomEntreprise' ? order : 'asc'}
                                        onClick={() => handleSort('nomEntreprise')}
                                    >
                                        Entreprise
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'createdAt' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'createdAt' ? order : 'asc'}
                                        onClick={() => handleSort('createdAt')}
                                    >
                                        Créé le
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sx={{ minWidth: 150 }}>Actions</StyledTableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {createurs.map((row: User, i: number) => (
                                <StyledTableRow key={i}>
                                    <TableCell className="border">{`${row.firstname} ${row.lastname}`}
                                    <br />
                                     {`HWK${row._id.slice(-5)}`}
                                    </TableCell>
                                    <TableCell className="border">{row.email}</TableCell>
                                    <TableCell className="border">{row.numero}</TableCell>
                                    <TableCell className="border">{row.idEntreprise?.citeEP ?? ''}</TableCell>
                                    <TableCell className="border">{row.idEntreprise?.nomEntreprise ?? ''}</TableCell>
                                    <TableCell className="border">{DateFormater(row.createdAt.toString())}</TableCell>
                                    <TableCell className="border">
                                        <Grid container alignItems={'center'} gap={1}>
                                            {/* <VisibilityIcon color="primary" onClick={() => navigate(`/utilisateur/createur/${row._id}`)} /> */}
                                            {/* <EditIcon color="primary" /> */}
                                            <DeleteIcon
                                                color="error"
                                                // onClick={() => {
                                                // //     setOpen(true)
                                                //     setSelectedItem(row._id)
                                                // }}
                                                onClick={() => handleDeleteClick(row._id)}
                                            />
                                        </Grid>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            {createurs.length > 0 && (
                <Box sx={{ display: 'flex', mt: 5 }}>
                    <Grid container alignItems="center" justifyContent="end" gap={1}>
                        <Stack spacing={2}>
                            <Pagination
                                showFirstButton={page === 1}
                                showLastButton={page === pageLastButton}
                                count={totalPage}
                                page={page}
                                onChange={handleChangePage}
                                color="primary"
                                shape="rounded"
                            />
                        </Stack>

                        <FormControl sx={{ m: 1, minWidth: 120, background: 'white' }} size="small">
                            <Select
                                // value={rowsPerPage}
                                value={rowsPerPage}
                                onChange={e => handleChangeRowsPerPage(+e.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={'5'}>5 / page</MenuItem>
                                <MenuItem value={'10'}>10 / page</MenuItem>
                                <MenuItem value={'25'}>25 / page</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Box>
            )}
            <UtilisateurConfirmSupprModal
                open={openLoginModal}
                onClose={() => setOpenLoginModal(false)}
                onAuthenticate={handleAuthenticate}
                selectedItem={selectedItem}
            />
        </div>
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>
      </div>
    </>
  );
};

export default UtilisateurASupprimerComponent;