import httpCommon from "../../../shared/services/http-common";
import { condition } from "../util";

class ConditionService {
    async getCondition() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/getCondition`)
    }
    async createCondition(data: condition) {
        try {
            const condition = await this.getCondition();
            if (condition.data.data.length === 0) {
                return httpCommon.post(`${process.env.REACT_APP_API_ROOT}/createCondition`, data)
            } else {
                return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/updateCondition/${condition.data.data[0]._id}`, data)
            }
        }
        catch (e) {
            console.error(e)
        }
    }
    async deleteCondition(id: string) {
        try {
            if (id) {
                return httpCommon.delete(`${process.env.REACT_APP_API_ROOT}/deleteCondition/${id}`)
            }
            console.log('id', id)
        }
        catch (e) {
            console.error(e)
        }
    }
}
const conditionService = new ConditionService()
export default conditionService