import React from 'react';
import '../css/CenteredImage.css'; 
import Header from '../../NavBar/components/header';
import Footer from '../../NavBar/components/Footer';


const PageimageComponent = () => {
    const imageUrl = 'imgpsh_fullsize_anim.jfif';
  return (
    <>
      <div className="centered-image-container">
        <img src={imageUrl} alt="Description of the image" className="centered-image" />
      </div>
    </>
  );
};

export default PageimageComponent;