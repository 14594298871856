import { Action } from 'redux'
import { Message } from '../interface/message.interface'

export enum MessageActionTypes {
    GET_MESSAGE = 'LIST_MESSAGE'
}

/**
 * get Dashboard value
 */
export interface GetMessageAction extends Action<MessageActionTypes.GET_MESSAGE> {
    messages: Message[]
}

export const getMessageAction = (messages: Message[]): GetMessageAction => ({
    type: MessageActionTypes.GET_MESSAGE,
    messages
})

export type MessageActions = GetMessageAction
