import { Backdrop, Button, CircularProgress } from "@mui/material"
import TextEditor from "./component/TextEditor"
import React from "react"
import conditionService from "./services/condition.service"
import ModaleValidationComponent from "../../shared/components/Modale/modale-validation.component"
import { toast } from "react-toastify"
import './CGV.css'

const CGVComponent = () => {
    const [condition, setCondition] = React.useState<string>('')
    const [data, setData] = React.useState<any>()
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [reload, setReload] = React.useState(0)
    const [afficheInput, setAfficheInput] = React.useState(false)
    const handleSave = async () => {
        try {
            setLoading(true)
            await conditionService.createCondition({ condition })
            setReload(reload + 1)
        }
        catch (e) {
            console.log(e)
        }
    }
    const handleDelete = async () => {
        try {
            setLoading(true)
            await conditionService.deleteCondition(data._id)
            fetchCondition()
            setReload(reload + 1)
        }
        catch (e) {
            toast.error(`Les conditions générales de ventes sont vide`)
            setLoading(false)
            console.log(e)
        }
    }
    console.log(data, 'condition', condition)
    const fetchCondition = () => {
        setLoading(true)
        conditionService.getCondition().then((res) => {
            console.log('tafiditra ato', res.data.data[0])
            if (res.data.data.length === 0) {
                setCondition('')
                setAfficheInput(true)
            } else {
                setCondition(res.data.data[0].condition)
                setAfficheInput(false)
            }
            setData(res.data.data[0])
            setLoading(false)
        }).catch((e) => setLoading(false)).finally(() => setLoading(false))
    }
    React.useEffect(() => {
        fetchCondition()
    }, [reload])
    return (
        <div className="flex justify-center flex-col align-center">
            {afficheInput && (
                <TextEditor value={condition} setValue={setCondition} />
            )}

            <div className="contenu-politique ml-5">
                {data && !afficheInput && (
                    <div dangerouslySetInnerHTML={{ __html: condition }} className=" h-[600px] overflow-auto" />
                )}
            </div>
            
            <div className="mt-5 flex justify-center gap-2">
                {afficheInput && (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            setReload(reload + 1)
                        }}
                    >
                        Annuler
                    </Button>
                )}
                {!afficheInput && (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => { setOpen(true) }}
                    >
                        Supprimer
                    </Button>
                )}
                {!afficheInput && (
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => setAfficheInput(!afficheInput)}
                    >
                        Modifier
                    </Button>
                )}
                {afficheInput && (
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleSave}
                        disabled={condition === '' || condition === '<p><br></p>'}
                    >
                        Enregistrer
                    </Button>
                )}
            </div>
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>

            <ModaleValidationComponent
                openModale={open}
                setOpen={setOpen}
                message={`Voulez-vous supprimer les conditions générales de ventes?`}
                handleValidate={handleDelete}
            />
        </div >
    )
}
export default CGVComponent