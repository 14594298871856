import React, { Component } from 'react'
import Header from '../../NavBar/components/header'
import Footer from '../../NavBar/components/Footer';
import politiqueService from '../../Politique/services/politique.service';

import './../css/Politique.css'
import ReactQuill from 'react-quill';

const Politique = () => {
  const [politique, setPolitique] = React.useState<string>('')
  const [loading, setLoading] = React.useState(false)
  const [footer, setFooter] = React.useState(false)
  React.useEffect(() => {
    setLoading(true)
    politiqueService.getPolitique().then((res) => {
      const isFilled = (window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
      setFooter(isFilled)
      console.log('tafiditra ato', isFilled)
      setPolitique(res.data.data[0].politique)
    }).catch((e) => setLoading(false)).finally(() => setLoading(false))
  }, [])
  return (
    <div className='px-5'>
      {/* <Header /> */}
      {/* <h3>Votre politique de confidentialités</h3> */}
      <div className="contenu-politique ml-5">
        {/* <div dangerouslySetInnerHTML={{ __html: politique }} /> */}
        <style>
                {`
                .no-border .ql-container.ql-snow {
                    border: none;
                }`}</style>
            <ReactQuill
                modules={{
                  toolbar: null
                }}
                theme="snow" 
                value={politique} 
                readOnly={true}
                className='no-border'
            ></ReactQuill>

      </div>
      {/* <Footer footer={footer} /> */}
    </div>
  )
}
export default Politique;
