import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const TextEditor = (props:ITextEditor) => {
    const modules = {
        toolbar: {
            container: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                [{align: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, 
                {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image', 'video'],
                ['clean'],
                ['emoji'],
            ],
        },
    };
    return (
        <div className="flex justify-center h-[600px] w-[100%]">
            <ReactQuill
                modules={modules}
                theme="snow" 
                value={props.value} 
                onChange={props.setValue}
                className='h-[550px] mt-5'
            ></ReactQuill>
        </div>
    )
}
export default TextEditor

interface ITextEditor{
    value: string
    setValue: (value: string) => void
}