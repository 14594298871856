import { useEffect, useState } from 'react'
import notificationService from '../services/notification.service'
import { Notification, NotificationStatus } from '../interfaces/notification.interface'
import { io } from 'socket.io-client'
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    MenuItem,
    Pagination,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    tableCellClasses
} from '@mui/material'
import styled from '@emotion/styled'
import { BASE_COLOR } from '../../../shared/constants/color.constant'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ModaleValidationComponent from '../../../shared/components/Modale/modale-validation.component'
import { useNavigate } from 'react-router-dom'
const socket = io(`${process.env.REACT_APP_API_ROOT}`)

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: BASE_COLOR.CONTAINER,
        border: 0
    },
    [`& .${tableCellClasses.root}`]: {
        borderBottom: 'none'
    }
}))

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: BASE_COLOR.CONTAINER,
        // fontSize: 15,
        fontWeight: 'bold'
        // textTransform: 'uppercase'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15
    }
}))

const NotificationComponent: React.FC<any> = ({ notification, loadNotifications }) => {
    const [listNotification, setNoficationData] = useState<Notification[]>([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [reload, setReload] = useState(0)
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState<Notification | null>(null)
    const [totalPage, setTotalPage] = useState(0)

    const handleDelete = (notification: Notification) => {
        notificationService
            .delete(notification._id)
            .then(() => setReload(prev => ++prev))
            .catch(console.log)
            .finally(() => setOpen(false))
    }

    const handleChangeRowsPerPage = (value: number) => {
        setPage(1)
        setRowsPerPage(value)
    }

    const getAllPropositions = () => {
        setLoading(true)
        notificationService
            .getAll(page, rowsPerPage)
            .then(val => {
                setTotalPage(+val.data.totalPage)
                setNoficationData(val.data.data)
            })
            .catch(console.log)
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        socket.on('receive', _ => {
            getAllPropositions()
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getAllPropositions()
        // eslint-disable-next-line
    }, [page, rowsPerPage, reload])

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage)
    }

    const [order, setOrder] = useState<'asc' | 'desc'>('asc')
    const [orderBy, setOrderBy] = useState<string>('fullName')

    const handleSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc'
        const newOrder = isAsc ? 'desc' : 'asc'
        setOrder(newOrder)
        setOrderBy(property)

        const sorted = [...listNotification].sort((a, b) =>
            newOrder === 'asc' ? handleSortBy(a, b, property) : handleSortBy(b, a, property)
        )

        setNoficationData(sorted)
    }

    const handleSortBy = (a: Notification, b: Notification, property: string) => {
        switch (property) {
            case 'domaine':
                return (a.mission?.domaine?.name ?? '').localeCompare(b.mission?.domaine?.name ?? '')
            case 'prestataire':
                return (a.prestataire?.firstname ?? '').localeCompare(b.prestataire?.firstname ?? '')
            case 'createur':
                return (a.createur?.firstname ?? '').localeCompare(b.createur?.firstname ?? '')
            case 'state':
                return getState(a.state).localeCompare(getState(b.state))
            default:
                return (a[property] ?? '').localeCompare(b[property])
        }
    }

    const getState = (status: string) => {
        switch (status) {
            case NotificationStatus.ACCEPTED:
                return 'Accepté'
            case NotificationStatus.PENDING:
                return 'En attente'
            case NotificationStatus.COMMENCE:
                return 'Débuté'
            case NotificationStatus.TERMINE:
                return 'Terminé'
            case NotificationStatus.PAYE:
                return 'Payé'
            default:
                return ''
        }
    }

    return (
        <>
            <TableContainer classes={{root: "!overflow-hidden"}} component="div" sx={{ width: '80vw', display: 'block' }}>
                <Table
                    stickyHeader
                    sx={{
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: 'none'
                        }
                    }}
                    className="border table-auto mx-9"
                >
                    <TableHead>
                        <StyledTableHeader sortDirection={orderBy === 'domaine' ? order : false} sx={{ minWidth: 150 }}>
                            <TableSortLabel
                                active={true}
                                direction={orderBy === 'domaine' ? order : 'asc'}
                                onClick={() => handleSort('domaine')}
                            >
                                Mission
                            </TableSortLabel>
                        </StyledTableHeader>
                        <StyledTableHeader sortDirection={orderBy === 'prestataire' ? order : false} sx={{ minWidth: 150 }}>
                            <TableSortLabel
                                active={true}
                                direction={orderBy === 'prestataire' ? order : 'asc'}
                                onClick={() => handleSort('prestataire')}
                            >
                                Préstataire
                            </TableSortLabel>
                        </StyledTableHeader>
                        <StyledTableHeader sortDirection={orderBy === 'createur' ? order : false} sx={{ minWidth: 150 }}>
                            <TableSortLabel
                                active={true}
                                direction={orderBy === 'createur' ? order : 'asc'}
                                onClick={() => handleSort('createur')}
                            >
                                Créateur
                            </TableSortLabel>
                        </StyledTableHeader>
                        <StyledTableHeader sortDirection={orderBy === 'state' ? order : false} sx={{ minWidth: 150 }}>
                            <TableSortLabel
                                active={true}
                                direction={orderBy === 'state' ? order : 'asc'}
                                onClick={() => handleSort('state')}
                            >
                                Statut
                            </TableSortLabel>
                        </StyledTableHeader>
                        <StyledTableHeader sx={{ minWidth: 150 }}>Actions</StyledTableHeader>
                    </TableHead>
                    <TableBody>
                        {listNotification.map((val: Notification, i: number) => (
                            <StyledTableRow key={i}>
                                <TableCell className="border">
                                    <div className="whitespace-nowrap">{val.mission?.domaine?.name ?? ''}</div>
                                </TableCell>
                                <TableCell className="border">
                                    <div className="whitespace-nowrap">{`${val.prestataire?.firstname} ${val.prestataire?.lastname}`}</div>
                                </TableCell>
                                <TableCell className="border">
                                    <div className="whitespace-nowrap">{`${val.createur?.firstname ?? ''} ${val.createur?.lastname ?? ''}`}</div>
                                </TableCell>
                                <TableCell className="border">
                                    {val.state === NotificationStatus.ACCEPTED ? (
                                        <Button variant="contained" color="success">
                                            Accepté
                                        </Button>
                                    ) : val.state === NotificationStatus.REFUSED ? (
                                        <Button variant="contained" color="error">
                                            Non-accepté
                                        </Button>
                                    ) : val.state === NotificationStatus.PENDING ? (
                                        <Button variant="contained" color="primary">
                                            En attente
                                        </Button>
                                    ) : val.state === NotificationStatus.COMMENCE ? (
                                        <Button variant="contained" color="info">
                                            Débuté
                                        </Button>
                                    ) : val.state === NotificationStatus.TERMINE ? (
                                        <Button variant="contained" color="warning">
                                            Terminé
                                        </Button>
                                    ) : val.state === NotificationStatus.PAYE ? (
                                        <Button variant="contained" color="success">
                                            Payé
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </TableCell>
                                <TableCell className="border">
                                    <Grid container alignItems={'center'} gap={1}>
                                        <VisibilityIcon color="primary" onClick={() => navigate(`/notification/${val._id}`)} />
                                        <DeleteIcon
                                            color="error"
                                            onClick={() => {
                                                setOpen(true)
                                                setSelectedItem(val)
                                            }}
                                        />
                                    </Grid>
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', mt: 5 }}>
                <Grid container alignItems="center" justifyContent="end" gap={1}>
                    <Stack spacing={2}>
                        <Pagination count={totalPage} page={page} onChange={handleChangePage} color="primary" shape="rounded" />
                    </Stack>

                    <FormControl sx={{ m: 1, minWidth: 120, background: 'white' }} size="small">
                        <Select
                            value={rowsPerPage}
                            onChange={e => handleChangeRowsPerPage(+e.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value={5}>5 / page</MenuItem>
                            <MenuItem value={10}>10 / page</MenuItem>
                            <MenuItem value={25}>25 / page</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Box>
            <ModaleValidationComponent
                openModale={open}
                setOpen={setOpen}
                message={`Voulez-vous supprimer l'utilisateur`}
                handleValidate={() => {
                    if (selectedItem) handleDelete(selectedItem)
                }}
            />
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>
        </>
    )
}

export default NotificationComponent
