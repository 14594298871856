import { connect } from 'react-redux'
import { AppState } from '../../../store/App.reducer'
import { selectMessage } from '../store/message.selector'
import MessageR from '../Message'
import { getMessageAction } from '../store/message.action'

type MessageProps = ReturnType<typeof mapStateToProps>
type MessageDispatchedProps = typeof mapDisptachToProps

export type MessInjectedProps = MessageProps & MessageDispatchedProps

const mapStateToProps = (state: AppState) => ({
    messages: selectMessage(state)
})

const mapDisptachToProps = {
    loadMessages: getMessageAction
}

const Message = connect(mapStateToProps, mapDisptachToProps)(MessageR)

export default Message
