import httpCommon from '../../../shared/services/http-common'
import { HourlyCost } from '../../Fees/interfaces/fees.interfaces'

class MinuteurServices {

    getMinuteur() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/munites`)
    }

    updateMinuteur(value: any) {
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/munites`,  { value })
    }
}

const minuteServices = new MinuteurServices()
export default minuteServices
