import React, { Component } from 'react'
import Header from '../../NavBar/components/header'
import conditionService from '../../CGV/services/condition.service'
import Footer from '../../NavBar/components/Footer'
import ReactQuill from 'react-quill'

const ConditionGenerale = () => {
  const [condition, setCondition] = React.useState<string>('')
  const [loading, setLoading] = React.useState(false)
  const [footer, setFooter] = React.useState(false)
  React.useEffect(() => {
    setLoading(true)
    conditionService.getCondition().then((res) => {
      const isFilled = (window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
      console.log("iiiiiiiiiiiiiiiii",isFilled)
      setFooter(isFilled)
      setCondition(res.data.data[0].condition)
    }).catch((e) => setLoading(false)).finally(() => setLoading(false))
  }, [])
  return (
    <div className='px-5'>
      {/* <Header /> */}
      {/* <div>Vos conditions générales</div> */}
      <div className="contenu-politique ml-5">
        {/* <div dangerouslySetInnerHTML={{ __html: condition }} /> */}
        <style>
                {`
                .no-border .ql-container.ql-snow {
                    border: none;
                }`}</style>
        <ReactQuill
                modules={{
                  toolbar: null
                }}
                theme="snow" 
                value={condition} 
                readOnly={true}
                className='no-border'
            ></ReactQuill>
      </div>
      {/* <Footer footer={footer} /> */}
    </div>
  )
}
export default ConditionGenerale;
