import styled from '@emotion/styled'
import {
    Backdrop,
    Box,
    CircularProgress,
    FormControl,
    Grid,
    MenuItem,
    Pagination,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    tableCellClasses
} from '@mui/material'
import { BASE_COLOR } from '../../../shared/constants/color.constant'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useNavigate } from 'react-router-dom'
import { Payment } from '../interfaces/payment.interface'
import { useEffect, useState } from 'react'
import paymentService from '../services/payment.service'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: BASE_COLOR.CONTAINER,
        border: 0
    },
    [`& .${tableCellClasses.root}`]: {
        borderBottom: 'none'
    }
}))

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: BASE_COLOR.CONTAINER,
        fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15
    }
}))

const PaymentsComponent: React.FC<any> = () => {
    const navigate = useNavigate()
    const [listPayments, setListPayments] = useState<Payment[]>([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    useEffect(() => {
        setLoading(true)
        paymentService
            .getAll(page, rowsPerPage)
            .then(val => setListPayments(val.data.data))
            .catch(console.log)
            .finally(() => setLoading(false))
    }, [page, rowsPerPage])

    const handleChangeRowsPerPage = (value: number) => setRowsPerPage(value)

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage)
    }

    const [order, setOrder] = useState<'asc' | 'desc'>('asc')
    const [orderBy, setOrderBy] = useState<string>('fullName')

    const handleSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc'
        const newOrder = isAsc ? 'desc' : 'asc'
        setOrder(newOrder)
        setOrderBy(property)

        const sorted = [...listPayments].sort((a, b) => (newOrder === 'asc' ? handleSortBy(a, b, property) : handleSortBy(b, a, property)))

        setListPayments(sorted)
    }

    const handleSortBy = (a: Payment, b: Payment, property: string) => {
        switch (property) {
            case 'payeur':
                return (a.payeur?.firstname ?? '').localeCompare(b.payeur?.firstname ?? '')
            case 'receveur':
                return (a.receveur?.firstname ?? '').localeCompare(b.receveur?.firstname ?? '')
            case 'type':
                return (a.proposition?.mission?.domaine?.name ?? '').localeCompare(a.proposition?.mission?.domaine?.name ?? '')
            case 'cout':
                return a.cout - b.cout
            default:
                return (a[property] ?? '').localeCompare(b[property])
        }
    }
    return (
        <>
            <TableContainer classes={{root: "!overflow-hidden"}} component="div" sx={{ width: '80vw', display: 'block' }}>
                <Table
                    stickyHeader
                    sx={{
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: 'none'
                        }
                    }}
                    className="border table-auto mx-9"
                >
                    <TableHead>
                        <StyledTableHeader sortDirection={orderBy === '_id' ? order : false} sx={{ minWidth: 150 }}>
                            <TableSortLabel active={true} direction={orderBy === '_id' ? order : 'asc'} onClick={() => handleSort('_id')}>
                                Paiement
                            </TableSortLabel>
                        </StyledTableHeader>
                        <StyledTableHeader sortDirection={orderBy === 'payeur' ? order : false} sx={{ minWidth: 150 }}>
                            <TableSortLabel
                                active={true}
                                direction={orderBy === 'payeur' ? order : 'asc'}
                                onClick={() => handleSort('payeur')}
                            >
                                Envoyeur
                            </TableSortLabel>
                        </StyledTableHeader>
                        <StyledTableHeader sortDirection={orderBy === 'receveur' ? order : false} sx={{ minWidth: 150 }}>
                            <TableSortLabel
                                active={true}
                                direction={orderBy === 'receveur' ? order : 'asc'}
                                onClick={() => handleSort('receveur')}
                            >
                                Receveur
                            </TableSortLabel>
                        </StyledTableHeader>
                        <StyledTableHeader sortDirection={orderBy === 'type' ? order : false} sx={{ minWidth: 150 }}>
                            <TableSortLabel active={true} direction={orderBy === 'type' ? order : 'asc'} onClick={() => handleSort('type')}>
                                Type
                            </TableSortLabel>
                        </StyledTableHeader>
                        <StyledTableHeader sortDirection={orderBy === 'cout' ? order : false} sx={{ minWidth: 150 }}>
                            <TableSortLabel active={true} direction={orderBy === 'cout' ? order : 'asc'} onClick={() => handleSort('cout')}>
                                Sommes
                            </TableSortLabel>
                        </StyledTableHeader>
                        <StyledTableHeader sx={{ minWidth: 150 }}>Actions</StyledTableHeader>
                    </TableHead>
                    <TableBody>
                        {listPayments.map((payment: Payment, i: number) => (
                            <StyledTableRow key={i}>
                                <TableCell className="border">
                                    <div className="whitespace-nowrap">{payment._id}</div>
                                </TableCell>
                                <TableCell className="border">
                                    <div className="whitespace-nowrap">
                                        {payment.payeur?.firstname} {payment.payeur?.lastname}
                                    </div>
                                </TableCell>
                                <TableCell className="border">
                                    <div className="whitespace-nowrap">
                                        {payment.receveur?.firstname} {payment.receveur?.lastname}
                                    </div>
                                </TableCell>
                                <TableCell className="border">
                                    <div className="whitespace-nowrap">{payment.proposition?.mission?.domaine?.name ?? ''}</div>
                                </TableCell>
                                <TableCell className="border">{payment.cout} €</TableCell>
                                <TableCell className="border">
                                    <VisibilityIcon color="primary" onClick={() => navigate(`/details-payments`,{state: {payment}})} />
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', mt: 5 }}>
                <Grid container alignItems="center" justifyContent="end" gap={1}>
                    <Stack spacing={2}>
                        <Pagination
                            count={Math.ceil(listPayments.length / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            color="primary"
                            shape="rounded"
                        />
                    </Stack>

                    <FormControl sx={{ m: 1, minWidth: 120, background: 'white' }} size="small">
                        <Select
                            value={rowsPerPage}
                            onChange={e => handleChangeRowsPerPage(+e.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value={5}>5 / page</MenuItem>
                            <MenuItem value={10}>10 / page</MenuItem>
                            <MenuItem value={25}>25 / page</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Box>
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>
        </>
    )
}

export default PaymentsComponent
